<template>
  <div class="index1">
    <div class="head">
        <div class="box lef">
            <div class="top">
                <img class="img" src="@/assets/image/icon1.png" alt="">
                <span>用户总体信息</span>
            </div>
            <div class="cont">
                <div class="listbox">
                    <div class="name">用户总数</div>
                    <div class="bot">
                        <img src="@/assets/image/icon2.png" alt="">
                        <div class="num">{{userSum}}<span>条</span></div>
                    </div>
                </div>
                <div class="listbox" v-for="(item,index) in listbox" :key="index">
                    <div class="name">{{item.name}}</div>
                    <div class="bot">
                        <img v-if="item.name =='院领导'" src="@/assets/image/icon3.png" alt="">
                        <img v-if="item.name =='接访管理员'" src="@/assets/image/icon5.png" alt="">
                        <img v-if="item.name =='普通用户'" src="@/assets/image/icon6.png" alt="">
                        <div class="num">{{item.sum}}<span>条</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box rig">
            <div class="top">
                <img class="img" src="@/assets/image/icon11.png" alt="">
                <span>接访总体信息</span>
            </div>
            <div class="cont">
                <div class="listbox">
                    <div class="name">接访总数</div>
                    <div class="bot">
                        <img  src="@/assets/image/icon7.png" alt="">
                        <div class="num">{{visitSum}}<span>条</span></div>
                    </div>
                </div>
                <div class="listbox" v-for="(item,index) in listboxone" :key="index">
                    <div class="name">{{item.name}}</div>
                    <div class="bot">
                        <img v-if="item.name =='接访总数'" src="@/assets/image/icon7.png" alt="">
                        <img v-if="item.name =='未审核数量'" src="@/assets/image/icon8.png" alt="">
                        <img v-if="item.name =='审核通过数量'" src="@/assets/image/icon9.png" alt="">
                        <img v-if="item.name =='已驳回数量'" src="@/assets/image/icon10.png" alt="">
                        <div class="num">{{item.sum}}<span>条</span></div>
                    </div>
                </div>
                <div class="listbox">
                    <div class="name">审核通过数量</div>
                    <div class="bot">
                        <img src="@/assets/image/icon9.png" alt="">
                        <div class="num">{{sum}}<span>条</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="tit">
            <div class="lef">
                <img class="icon" src="@/assets/image/icon2.png" alt="">
                <span>接访总数按时间变化曲线图</span>
            </div>
            <div class="rig">
                <el-select @change="change" v-model="mouth" placeholder="请选择" size="mini">
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div id="main"></div>
    </div>
    <div class="footer">
        <div class="tit">
            <img class="log" src="@/assets/image/icon13.png" alt="">
            <div>微信信息开关</div>
        </div>
        <!-- <div class="content">
            <div class="box">
                <el-switch
                class="demo"
                v-model="msgStat"
                active-text="启用微信发送"
                inactive-text="取消微信发送"
                @change="changemsgStat">
                </el-switch>
            </div>
        </div> -->
    </div>
    <el-table
          :data="tableData"
          style="width: 100% "
          :header-cell-style="{textAlign:'center'}"
          :cell-style="{textAlign:'center'}">
          <el-table-column
            prop="text"
            label="业务内容"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="progress"
            label="业务进度"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="min"
            label="距离发送时间（分）"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <el-switch 
                  v-model="scope.row.switchmsg"
                  @change="changeStatus($event,scope.row,scope.$index)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="备注"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="editAndAddMaterial(scope.row)" type="text" size="small" icon="el-icon-edit">编辑</el-button>
            </template>
          </el-table-column>
    </el-table>
    <el-dialog title="编辑" :visible.sync="dialogFormVisible" width="680px">
        <el-form :model="form">
            <el-form-item label="业务内容" :label-width="formLabelWidth">
                <el-input v-model="form.text" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="业务进度" :label-width="formLabelWidth">
                <el-input v-model="form.progress" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="距离发送时间" :label-width="formLabelWidth">
                <el-input-number v-model="form.min" :min="0" label="描述文字"></el-input-number>
            </el-form-item>
            <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input placeholder="请输入内容" v-model="form.name" :disabled="true"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="makeSure()">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import{controllist,updateMsgStatic,getMsgOne,updateMsgOne,updateMsg} from '@/utils/api'
import { Message } from 'element-ui';
export default {
    inject: ["reload"],
    data(){
        return{
            userSum:'',
            listbox:[],
            visitSum:'',
            listboxone:[],
            options: [{
                id: '01',
                label: '01月'
                }, {
                id: '02',
                label: '02月'
                }, {
                id: '03',
                label: '03月'
                }, {
                id: '04',
                label: '04月'
                }, {
                id: '05',
                label: '05月'
                }, {
                id: '06',
                label: '06月'
                }, {
                id: '07',
                label: '07月'
                }, {
                id: '08',
                label: '08月'
                }, {
                id: '09',
                label: '09月'
                }, {
                id: '10',
                label: '10月'
                }, {
                id: '11',
                label: '11月'
                }, {
                id: '12',
                label: '12月'
            }],
            mouth: '',
            msgStat:true,
            tableData: [],
            sum:'',
            dialogFormVisible:false,
            form:{
                name:'',
                text:'',
                progress:'',
                min:0,
            },
            formLabelWidth: '100px',
            idx:'',
            time:''
        }
    },
    methods: {
      change(e){
        // console.log(e)
        this.mouth = e
        this.getconslist()
      },
      drawChart(ydata,sum,mouth) {
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        let myChart = this.$echarts.init(document.getElementById("main"));
        let time = this.time
        // 指定图表的配置项和数据
        let option = {
            tooltip: {
                trigger: 'axis',
                backgroundColor: 'rgba(0, 132, 255, 1)',
                formatter: function(val){
                    // const yy = new Date().getFullYear();
                    // const rr = this.vlaue
                    // console.log(val[0].data,val[0].axisValueLabel);
                    return `接访数 : ${val[0].data}`+'<br/>' + `预约日期 : ${time}年-${mouth}月-${val[0].axisValueLabel}`
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ydata
            },
            yAxis: {
                type: 'value'
            },
            series: [
            {
                symbol: 'none',
                data: sum,
                type: 'line',
                areaStyle: {
                    color:{
                        type: 'line',
                        x:0,
                        y:0,
                        x2:0,
                        y2:1,
                        colorStops:[
                            {
                            offset:0,color:'#1890FF'
                            },
                            {
                            offset:1,color:'#fff'
                            }
                        ],
                        global:false,
                    }
                },
                color:'#1890FF',
                smooth: true
            }
            ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      // 控制台数据
      getconslist(){
        let param = {
            month:this.mouth
        }
        controllist(param).then(res =>{
            console.log('列表',res);
            this.sum = res.data.data.visitSumOk
            this.userSum = res.data.data.userSum
            this.listbox=res.data.data.userTotal
            this.visitSum=res.data.data.visitSum
            res.data.data.visitTotal.map(item =>{
                item.name = item.name+'数量'
                return item
            })
            // console.log('完整',res.data.data.visitTotal);
            let listone = res.data.data.visitTotal.slice(0,2)
            // console.log('截取',listone);
            this.listboxone=listone
            let ydata = []
            let sum = []
            let mouth = res.data.data.month
            res.data.data.table.forEach(item =>{
                item.seriesDateList = item.seriesDateList+'日'
                item.date = item.date.slice(0,4)
                ydata.push(item.seriesDateList)
                sum.push(item.xaxisDateList)
            })
            if(res.data.data.table.length !== 0){
                this.time = res.data.data.table[0].date 
            }
            this.drawChart(ydata,sum,mouth);
            if(res.data.data.msgStat == 1){
                this.msgStat = true
            }else{
                this.msgStat = false
            }
        })
      },
      changemsgStat(e){
        // console.log(e);
        if(e == true){
            var stat = 1
        }else if(e == false){
            var stat = 0
        }
        updateMsgStatic({isStatic:stat}).then(res=>{
            // console.log(res);
        })
      },
      changeStatus(e,row,index){
        if(e == true){
            var stat = 1
        }else if(e == false){
            var stat = 0
        }
        updateMsg({
            userId:row.id,
            switchmsgId:stat
        }).then(res =>{
            if(res.data.code == 200){
                Message({ message:'修改状态成功',type:"success" })
            }
        })
      },
      makeSure() {
        let params = {
          id:this.idx,
          text: this.form.text,
          progress: this.form.progress,
          min: this.form.min,
        }
        this.dialogFormVisible = false
        updateMsgOne(params).then(res =>{
          if(res.data.code == 200){
            this.reload();
            Message({ message:'保存成功',type:"success" })
          }
        })
      },
      editAndAddMaterial(row) {
        this.dialogFormVisible = true
        this.idx = row.id
        getMsgOne({
            msgId:row.id
        }).then(res =>{
            // console.log(res);
            this.form.name = res.data.data.name
            this.form.text = res.data.data.text
            this.form.progress = res.data.data.progress
            this.form.min = res.data.data.min
        })
      },
    },
    mounted() {
        this.getconslist()
        let param = {
                month:this.mouth
        }
        controllist(param).then(res =>{
            this.mouth = res.data.data.month
            res.data.data.msgTable.map(item=>{
                if(item.switchmsg == 1){
                    item.switchmsg = true
                }else if(item.switchmsg == 0){
                    item.switchmsg == false
                }
                return item
            })
            this.tableData=res.data.data.msgTable
        })
    },
    created() {
    },
}
</script>

<style  lang="less" scoped>
.index1{
    .head{
        display: flex;
        justify-content: space-between;
        .box{
            width: 49%;
            height: 172px;
            background: #fff;
            box-shadow: 0px 3px 20px 0px rgba(131,131,131,0.1800);
            border-radius: 4px;
            .top{
                height: 52px;
                font-size: 18px;
                font-weight: 600;
                color: rgba(0,0,0,0.7500);
                line-height: 24px;
                border-bottom: 1px solid #D8D8D8;
                padding: 0 24px;
                display: flex;
                align-items: center;
                .img{
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                }
            }
            .cont{
                display: flex;
                justify-content: space-between;
                padding: 24px;
                .listbox{
                    font-size: 18px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 25px;
                    .bot{
                        display: flex;
                        align-items: center;
                        margin-top: 16px;
                        .icon{
                            width: 24px;
                            height: 24px;
                            background: red;
                            margin-right: 10px;
                        }
                        .num{
                            font-size: 30px;
                            font-weight: bold;
                            color: #333333;
                            line-height: 35px;
                            margin-right: 10px;
                            span{
                                font-size: 16px;
                                color: #666666;
                                line-height: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
    .content{
        margin-top: 24px;
        background: #fff;
        .tit{
            height: 52px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 23px;
            border-bottom: 1px solid #D8D8D8;
            .lef{
                font-size: 18px;
                font-weight: 600;
                color: rgba(0,0,0,0.6000);
                line-height: 24px;
                display: flex;
                align-items: center;
                .icon{
                    width: 16px;
                    height: 16px;
                    margin-right: 9px;
                }
            }
            .rig{
                width: 81px;
                height: 30px;
                background: #FFFFFF;
                // border-radius: 16px;
                // border: 1px solid rgba(0,0,0,0.1500);
                .el-select,.el-option{
                    width: 81px;
                    height: 30px;
                    border-radius: 16px;
                }
            }
        }
        #main{
            height: 600px;
        }
    }
    .footer{
        margin-top: 16px;
        background: #fff;
        .tit{
            height: 52px;
            display: flex;
            align-items: center;
            padding: 0 23px;
            border-bottom: 1px solid #D8D8D8;
            font-size: 18px;
            font-weight: 600;
            color: rgba(0,0,0,0.7500);
            line-height: 24px;
            .log{
                width: 16px;
                height: 10px;
                margin-right: 8px;
            }
        }
        .content{
            color: #000000;
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 130px;
            .demo .el-switch__label{
                z-index: 999;
                font-size: 16px;
                font-weight: bold;
                line-height: 22px;
            }
            .demo.el-switch__label.is-active{
                z-index: 999;
                color: #000000 !important;
            }
        }
    }
}
</style>